exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".settings-menu_icon_1kXwl {\n    width: 1.5rem;\n}\n\n.settings-menu_theme-label_1AHJT {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n}\n\n.settings-menu_option_3Pqud {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: .5rem;\n}\n\n.settings-menu_check_3urTz {\n    margin: 0 .25rem 0 0;\n    visibility: hidden;\n}\n\n[dir=\"rtl\"] .settings-menu_check_3urTz {\n    margin: 0 0 0 .25rem;\n}\n\n.settings-menu_selected_1h3PX {\n    visibility: visible;\n}\n\n.settings-menu_submenu-label_14Jrk {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n}\n\n.settings-menu_dropdown-label_3OEOH {\n    margin: 0 0.5rem 0 0.25rem;\n}\n\n[dir=\"rtl\"] .settings-menu_dropdown-label_3OEOH {\n    margin: 0 0.25rem 0 0.5rem;\n}\n\n.settings-menu_expand-caret_1eVT8 {\n    -webkit-transform: rotate(-90deg);\n        -ms-transform: rotate(-90deg);\n            transform: rotate(-90deg);\n}\n\n[dir=\"rtl\"] .settings-menu_expand-caret_1eVT8 {\n    -webkit-transform: rotate(90deg);\n        -ms-transform: rotate(90deg);\n            transform: rotate(90deg);\n}\n\n/* Extra padding to deal with scrollbar overlapping options in Firefox */\n\n[dir=\"ltr\"] .settings-menu_language-menu-item_3MiEs {\n    padding-right: 1rem;\n}\n\n[dir=\"rtl\"] .settings-menu_language-menu-item_3MiEs {\n    padding-left: 1rem;\n}\n\n.settings-menu_language-submenu_2rhUy > ul {\n    max-height: calc(100vh - 5rem); /* Fallback if dvh not supported */\n    max-height: calc(100dvh - 5rem);\n}\n\n@media only screen and (max-width: 1024px) {\n    .settings-menu_dropdown-label_3OEOH {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"icon": "settings-menu_icon_1kXwl",
	"theme-label": "settings-menu_theme-label_1AHJT",
	"themeLabel": "settings-menu_theme-label_1AHJT",
	"option": "settings-menu_option_3Pqud",
	"check": "settings-menu_check_3urTz",
	"selected": "settings-menu_selected_1h3PX",
	"submenu-label": "settings-menu_submenu-label_14Jrk",
	"submenuLabel": "settings-menu_submenu-label_14Jrk",
	"dropdown-label": "settings-menu_dropdown-label_3OEOH",
	"dropdownLabel": "settings-menu_dropdown-label_3OEOH",
	"expand-caret": "settings-menu_expand-caret_1eVT8",
	"expandCaret": "settings-menu_expand-caret_1eVT8",
	"language-menu-item": "settings-menu_language-menu-item_3MiEs",
	"languageMenuItem": "settings-menu_language-menu-item_3MiEs",
	"language-submenu": "settings-menu_language-submenu_2rhUy",
	"languageSubmenu": "settings-menu_language-submenu_2rhUy"
};